import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

const NotFoundPage = props => (
  <Layout location={props.location} delayNavigation={false} pageName={"404"}>
    <SEO />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
